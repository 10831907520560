* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button,
a {
  cursor: pointer;
}

* {
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: auto;
  background: #fafafa;
}

body,
html,
#root {
  height: 100%;
  font-size: 16px;
  display: flex;
  flex-direction:column;
  overflow-x: hidden;

  @media (max-width: 1801px) {
    font-size: 14px;
  }

  @media (max-width: 1401px) {
    font-size: 13px;
  }

  @media (max-width: 1171px) {
    font-size: 12px;
  }
}

input, textarea, input::placeholder, textarea::placeholder {
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  text-overflow: ellipsis;
}